import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CloudBike from "./components/FirebaseBikeAndNonin.js";
import BluetoothConnection from "./components/BluetoothConnection";
import HeartRateLevelAlert from "./components/Heart_Rate_Alert/HeartRateLevelAlert";
import DeviceRegister from './components/Device_Register/DeviceRegister';
import ArmBikeDataDisplay from './components/ArmBikeDataDisplay/ArmbikeDataDisplay.js';
import NoninBLEConnection from './components/NoninBLEConnection/NoninBLEConnection.js';
import FitbitManager from './components/FitbitCompoents/FitbitManager.js'
import FitbitLoginPage from './components/FitbitCompoents/FitbitLoginPage.js';
import FitbitDashboard from './components/FitbitCompoents/FitbitDashboard.js';
import CommunityDashBoard from './components/CommunityPost/CommunityDashboard.js';
import FullPost from './components/CommunityPost/FullPost.js'

import MediapipePost from './components/PoseDetection/MediapipePost.js';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/:id" element={<CloudBike />}></Route>
        <Route path="/connect" element={<BluetoothConnection />}></Route>
        <Route path="/hr_level_alert/:id" element={<HeartRateLevelAlert />}></Route>
        <Route path="/bike_register/:id" element={<DeviceRegister />}></Route>
        <Route path="/armbike_data/:id" element={<ArmBikeDataDisplay />}></Route>
        <Route path='/nonin/:id/:sessionId'element={<NoninBLEConnection />}></Route>
        <Route path='/fitbit/manager/:id'element={<FitbitManager />}></Route>
        <Route path='/fitbit/login'element={<FitbitLoginPage />}></Route>
        <Route path='/fitbit/dashboard'element={<FitbitDashboard />}></Route>
        <Route path='/community/:user/:id'element={<CommunityDashBoard />}></Route>
        <Route path='/full_post/:id/:user/:userId'element={<FullPost />}></Route>
        <Route path='/media_pose_detection/:id'element={<MediapipePost />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
